<template>

   <el-card shadow="hover">
        <el-row align="middle">
            <el-col :span="12">
                <div class="text-left">
                    <h3>{{ $t('administratorsList') }}</h3>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="text-right">
                    <el-button size="default" @click="addItem()">
                        {{ $t('add') }}
                    </el-button>
                </div>
            </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-table :data="pagedTableData" :empty-text="$t('noData')" style="width: 100%" border stripe v-loading="isLoading">

            <el-table-column type="index" :index="indexMethod"> </el-table-column>
            <el-table-column :label="$t('avatar')" prop="avatar" width="70">
                <template #default="scope">
                    <el-avatar :src="scope.row.avatar" v-if="scope.row.avatar"></el-avatar>
                    <el-avatar v-else> {{ getNameInitials(scope.row.name || '') }} </el-avatar>
                </template>
            </el-table-column>
            <el-table-column sortable :label="$t('id')" prop="uid"> </el-table-column>
            <el-table-column sortable :label="$t('name')" prop="name"> </el-table-column>
            <el-table-column :label="$t('password')">

                <template #default="scope">
                    <template v-if="scope.row.role != Roles.SUPERADMIN">
                        <el-button size="small" type="info" @click="viewPassword(scope.row.password)">
                            {{ $t('view') }}
                        </el-button>
                        <el-button size="small" type="primary" @click="copyPassword(scope.row.password)">
                            {{ $t('copy') }}
                        </el-button>
                    </template>
                </template>

            </el-table-column>

            <el-table-column align="right">

                <template #header>
                    <el-input v-model="search" size="small" :placeholder="$t('search')" />
                </template>

                <template #default="scope">
                    <template v-if="scope.row.role != Roles.SUPERADMIN">
                        <el-button size="small" @click="editItem(scope.$index, scope.row)">
                            {{ $t('edit') }}
                        </el-button>
                        <el-button size="small" type="danger" @click="deleteItem(scope.$index, scope.row)">
                            {{ $t('delete') }}
                        </el-button>
                    </template>
                </template>

            </el-table-column>

        </el-table>

        <div style="text-align: center; margin-top: 20px">

            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="setPage"
                :hide-on-single-page="true"
                :page-size="pageSize"
                :total="total">
            </el-pagination>

        </div>
  </el-card>

  <el-dialog :title="currentEditItem ? $t('editAdmin') : $t('addAdmin')" v-model="adminDialog" width="40%">

    <el-form :model="admin" :rules="rules" :ref="currentEditItem ? 'editForm' : 'addForm'">

        <el-form-item prop="name">
            <el-input
                :placeholder="$t('lastAndFirstNames')"
                v-model="admin.name"/>
        </el-form-item>

    </el-form>

    <template #footer>
        <span class="dialog-footer">
            <el-button @click="cancelForm(currentEditItem ? 'editForm' : 'addForm')">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="submitForm(currentEditItem ? 'editForm' : 'addForm')">{{ $t('confirm') }}</el-button>
        </span>
    </template>
  </el-dialog>

</template>

<script>
import { mapState, mapActions } from 'vuex'

import { Roles } from '@/utils/enums'
import { genPassword, getNameInitials } from '@/utils/functions'

import { v4 as uuidv4 } from 'uuid'
import sanitizer from 'string-sanitizer'
import useClipboard from 'vue-clipboard3'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'Administrators',
    mixins: [accessManager],
    data() {
      return {
        Roles,
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        admin: {
            name: ''
        },
        rules: {
            name: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ]
        },
        currentEditItem: null,
        adminDialog: false,
        isLoading: false,
        tableData: [],
        pageSize: 6,
        search: '',
        total: 0,
        page: 1
      }
    },
    created() {

        this.fetchAdministrators()

    },
    computed: {
        ...mapState({
            userData: state => state.session.userData
        }),
        pagedTableData() {

            this.total = this.searching.length

            return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

        },
        searching() {

            if (!this.search) {
                this.total = this.tableData.length
                return this.tableData
            }

            this.page = 1

            return this.tableData.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()))

        }
    },
    methods: {
        getNameInitials,
        ...mapActions({
            fetchAdminDB: 'admin/fetchAdminDB',
            fetchUserDB: 'user/fetchUserDB'
        }),
        setPage (val) {

            this.page = val

        },
        indexMethod(index) {

            return index + ((this.page - 1) * this.pageSize) + 1

        },
        async fetchAdministrators(){

            this.isLoading = true

            let resp = await this.fetchAdminDB({ action: 'getAdministrators' })

            if (resp.success){

                if (resp.data) this.tableData = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

            this.isLoading = false

        },
        async genUid(uidSrc, addSalt){

            var uid = sanitizer.sanitize(uidSrc.toLowerCase()).substring(0, 10)

            if (addSalt) uid += this.$_.random(1, 99, false)

            var task = await this.fetchUserDB({
                action: 'checkUserExists',
                params: {
                    uid: uid
                }
            })

            if (task.success && !task.data) {

                return uid

            }else{

                return await this.genUid(uidSrc, true)

            }

        },
        addItem(){

            this.currentEditItem = null

            this.admin.name = ''

            this.adminDialog = true

        },
        async editItem(index, item){

            this.currentEditItem = item

            this.admin.name = item.name

            this.adminDialog = true

        },
        deleteItem(index, item){

            this.$confirm(this.$t('deleteAdminQuestion'), this.$t('delete'), {
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
            })
            .then(async () => {

                let task = await this.fetchUserDB({
                    action: 'deleteUser',
                    params: {
                        id: item.id
                    }
                })

                if (task.success){

                    this.$message.success(this.$t('success'))
                    this.fetchAdministrators()

                }else{

                    this.$message.error(this.$t('anErrorOccured'))

                }

            })
            .catch(() => {

            })

        },
        submitForm(ref){

            this.$refs[ref].validate(async (valid) => {
                if (valid) {

                    if (ref === 'addForm'){

                        let uid = await this.genUid(this.admin.name, false)

                        let task = await this.fetchUserDB({
                            action: 'addUser',
                            params: {
                                uid: uid,
                                name: this.admin.name,
                                role: Roles.ADMIN,
                                hash_id: uuidv4(),
                                password: genPassword()
                            }
                        })

                        console.log(task, uid)

                        if (task.success) this.$message.success(this.$t('success'))
                        else this.$message.error(this.$t('anErrorOccured'))

                    }else{

                        let newData = {}

                        if (this.currentEditItem.name !== this.admin.name) {
                            newData['name'] = this.admin.name
                        }

                        if (!this.$_.isEmpty(newData)){

                            let task = await this.fetchUserDB({
                                action: 'editUser',
                                params: {
                                    id: this.currentEditItem.id,
                                    data: newData
                                }
                            })

                            if (task.success) this.$message.success(this.$t('success'))
                            else this.$message.error(this.$t('anErrorOccured'))

                        }

                    }

                    this.cancelForm(ref)
                    this.fetchAdministrators()

                } else {
                    return false
                }
            })

        },
        cancelForm(ref){

            this.$refs[ref].resetFields()

            this.adminDialog = false

        },
        viewPassword(password){

            this.$alert(password, {
                center: true,
                showClose: true,
                closeOnClickModal: true,
                showCancelButton: false,
                showConfirmButton: false,
                closeOnPressEscape: true,
                iconClass: 'lock'
            })

        },
        async copyPassword(password){

            const { toClipboard } = useClipboard()

            try {

                await toClipboard(password)

                this.$message.success(this.$t('success'))

            } catch (e) {

                console.error(e)

                this.$message.error(this.$t('anErrorOccured'))

            }

        }
    },
}
</script>